import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { Howl } from "howler";
import http from "../api/http";
import Conversation from "../types/conversation";
import Message from "../types/message";
import UserSimple from "../types/userSimple";

interface TechChatsState {
  techChats: UserSimple[] | null;
  status: "idle" | "loading" | "failed";

  currentUnreadTech: number;
  currentTechMessage?: Message;
  currentTechConversation: Message[];
  techConversations: Conversation[];
  techChatHospitalId?: number;
}

const initialState: TechChatsState = {
  techChats: null,
  status: "idle",
  currentUnreadTech: 0,
  currentTechMessage: undefined,
  currentTechConversation: [],
  techConversations: [],
};

export const fetchTechChatList = createAsyncThunk<UserSimple[], number>("tech-chats/fetch", async (id: number) => {
  const response = await http.get<UserSimple[]>(`/teams/${id}`);
  return response.data;
});

export const sendTechChat = createAsyncThunk<Message, Message>(`tech-chats/send`, async (chat: Message) => {
  const response = await http.post<Message>(`chats`, chat);
  return response.data;
});

export const getTechMessages = createAsyncThunk<Message[], number>(`tech-chats/get-messages`, async (id: number) => {
  const response = await http.get<Message[]>(`chats/techs/${id}/messages`);
  return response.data;
});

export const readTechMessage = createAsyncThunk<null, number>(`tech-chats/read`, async (id: number, { dispatch }) => {
  const response = await http.patch(`chats/${id}/read`);
  dispatch(getTechConversations());
  return response.data;
});

export const getTechConversations = createAsyncThunk<Conversation[]>(`tech-chats/get-conversations`, async () => {
  const response = await http.get<Conversation[]>(`chats/tech/conversations`);
  const result = response.data;
  return result;
});

const techChatSlice = createSlice({
  name: "techChats",
  initialState,
  reducers: {
    toggleTechChatHospital(state, action) {
      state.techChatHospitalId = action.payload;
    },
    clearTechConversation(state) {
      state.currentTechConversation = initialState.currentTechConversation;
      state.techChats = initialState.techChats;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTechChatList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTechChatList.fulfilled, (state, action) => {
        state.status = "idle";
        state.techChats = action.payload;
      })
      .addCase(fetchTechChatList.rejected, (state) => {
        state.status = "failed";
      })
      // Send Chat
      .addCase(sendTechChat.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendTechChat.fulfilled, (state, action) => {
        state.status = "idle";
        state.currentTechMessage = action.payload;

        let openChatWindow = state.currentTechConversation;
        if (state.techChatHospitalId === action.payload?.hospitalId!) {
          openChatWindow?.push(action.payload);
          state.currentTechConversation = openChatWindow;
        }
      })
      .addCase(sendTechChat.rejected, (state) => {
        state.status = "failed";
      })
      // Get Messages
      .addCase(getTechMessages.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTechMessages.fulfilled, (state, action) => {
        state.status = "idle";
        const hospitalId = action.payload[0]?.hospitalId!;
        if (hospitalId === state.techChatHospitalId) {
          state.currentTechConversation = action.payload;
        }
      })
      .addCase(getTechMessages.rejected, (state) => {
        state.status = "failed";
      })
      // Read Message
      .addCase(readTechMessage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(readTechMessage.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(readTechMessage.rejected, (state) => {
        state.status = "failed";
      })
      // Get Conversations
      .addCase(getTechConversations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTechConversations.fulfilled, (state, action) => {
        state.status = "idle";
        state.techConversations = action.payload;
        let newUnread = action.payload.reduce((a, b) => a + b.unread!, 0);
        state.currentUnreadTech = newUnread;
      })
      .addCase(getTechConversations.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default techChatSlice.reducer;
export const { clearTechConversation, toggleTechChatHospital } = techChatSlice.actions;
