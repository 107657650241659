import { Middleware } from "@reduxjs/toolkit";
import { hubActions } from "../slices/hub";
import { HttpTransportType, HubConnectionBuilder, JsonHubProtocol, LogLevel } from "@microsoft/signalr";
import getToken from "../helpers/getToken";
import { clearConversation, getConversations, getMessages, playChatSound, toggleChat } from "../slices/chat";
import storeRedux from "../store";
import { fetchAllCases, removeCase, replaceCase } from "../slices/cases";
import { fetchAssignedCases, fetchCreatedCases } from "../slices/qa";
import Announcement from "../types/announcement";
import { newAnnouncement } from "../slices/announcement";
import Case from "../types/case";
import { fetchChatList, fetchUserSchedule } from "../slices/schedule";
import { fetchHospitals } from "../slices/hospital";
import { check, logout } from "../slices/auth";
import {
  clearTeamConversation,
  fetchTeamChat,
  getTeamConversations,
  getTeamMessages,
  playTeamChatSound,
  setPinnedTeamChat,
} from "../slices/teamchats";
import Option from "../types/option";
import { fetchScheduledBreak } from "../slices/users";
import { getIrStudies, getUnreadStudies } from "../slices/irStudy";
import { getUnreadCases, searchInterestingCases } from "../slices/interestingCases";
import { getTechConversations, getTechMessages } from "../slices/techChats";

const hubMiddleware: Middleware = (store) => (next) => async (action) => {
  const { dispatch } = storeRedux;

  if (!hubActions.startConnecting.match(action)) {
    return next(action);
  }

  const options = {
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets,
    accessTokenFactory: () => getToken(),
  };

  const connection = new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_API_BASE_URL}/r/hub`, options)
    .withAutomaticReconnect()
    .withHubProtocol(new JsonHubProtocol())
    .configureLogging(LogLevel.Information)
    .build();

  await connection.start();
  console.log("SignalR connection established");

  const newTeamChat = () => {
    connection.invoke("addnewteamchat").then((data) => {
      dispatch(fetchTeamChat());
    });
  };

  const removeTeamChat = () => {
    connection.invoke("removeteamchat").then((data) => {
      dispatch(fetchTeamChat());
      dispatch(setPinnedTeamChat(false));
      dispatch(clearTeamConversation());
      dispatch(toggleChat({ id: undefined, title: "", open: false, team: false }));
    });
  };

  connection.on("StopHub", (forcedLogout: boolean) => {
    console.log("stopping connection");
    connection.stop();

    if (!!forcedLogout) {
      dispatch(logout());
    }
  });

  connection.on("ReceivedChat", (fromUserId: number, teamChat: boolean) => {
    if (teamChat) {
      dispatch(getTeamConversations());
    } else {
      dispatch(getConversations());
    }

    const state: any = store.getState();
    const chatId = state.chat?.chatOpen?.id;

    const loggedInUser = state.auth?.loggedInUser?.id!;
    const conversationId = !!teamChat ? 0 : fromUserId;

    if (conversationId === chatId && !teamChat) {
      dispatch(getMessages(conversationId));
    } else {
      dispatch(getTeamMessages());
    }

    if (fromUserId !== loggedInUser) {
      if (!!teamChat) {
        dispatch(playTeamChatSound());
      } else {
        dispatch(playChatSound());
      }
    }
  });

  connection.on("ReceivedTechChat", (fromUserId: number, hospitalId: number) => {
    dispatch(getTechConversations());
    const state: any = store.getState();
    const loggedInUser = state.auth?.loggedInUser?.id!;
    const selectedTechChat = state.techChats?.techChatHospitalId!;
    const openChat = state.chat?.chatOpen?.id;

    // only get messages if this chat is open!
    if (selectedTechChat === hospitalId && hospitalId === openChat) {
      dispatch(getTechMessages(hospitalId));
    }

    if (fromUserId !== loggedInUser) {
      dispatch(playTeamChatSound());
    }
  });

  connection.on("CaseStatusChange", (caseCard: Case) => {
    const state: any = store.getState();
    const loggedInUser = state.auth?.loggedInUser;

    const isChallenged = caseCard.status.id === 6;
    const isJudge = !!loggedInUser?.roles?.find((role: Option) => role?.id === 6);

    if (
      !!caseCard.qaError &&
      ((caseCard.currentUser?.id !== loggedInUser?.id! && !isChallenged) || (isChallenged && !isJudge))
    ) {
      dispatch(removeCase(caseCard.id));
    } else {
      dispatch(replaceCase(caseCard));
    }
  });

  connection.on("ReloadCases", () => {
    dispatch(fetchAllCases());
  });

  connection.on("CaseClosed", (id: number) => {
    dispatch(removeCase(id));
  });

  connection.on("QaCaseChange", () => {
    dispatch(fetchAssignedCases());
    dispatch(fetchCreatedCases());
  });

  connection.on("NewAnnouncement", (announcement: Announcement) => {
    dispatch(newAnnouncement(announcement));
  });

  connection.on("UpdateSchedule", () => {
    const state: any = store.getState();
    const loggedInUser = state.auth?.loggedInUser;
    dispatch(fetchUserSchedule());
    dispatch(fetchChatList());

    if (loggedInUser) {
      dispatch(fetchScheduledBreak(loggedInUser?.id!));
    }
  });

  connection.on("RefreshHospitals", () => {
    dispatch(fetchHospitals());
    dispatch(check(false));
  });

  connection.on("UpdateTeam", () => {
    dispatch(fetchTeamChat());
  });

  connection.on("UpdateGroups", (add: boolean) => {
    if (add === true) {
      newTeamChat();
    } else {
      removeTeamChat();
    }
  });

  connection.on("NewIrStudy", () => {
    dispatch(getUnreadStudies());
    dispatch(getIrStudies({ pageNumber: 1, pageSize: 10 }));
  });

  connection.on("NewInterestingCase", () => {
    dispatch(getUnreadCases());
    dispatch(searchInterestingCases({ pageNumber: 1, pageSize: 10 }));
  });

  next(action);
};

export default hubMiddleware;
